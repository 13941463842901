<template>
	<!-- 维修 -->
	<div class="maintain text-center">
		<div class="banner">
			<el-carousel height="1000px">
				<el-carousel-item v-for="item in imgList" :key="item.url">
					<img :src="item.url" alt class="bannerImg " />
					  
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="maintainBox pos-rel">
			<div class=" pos-center ">
				<h3 class="mb-30">圣名维修</h3>
				<h3 class="mb-30" style="font-size: 24px;">我们拥有官方及外部经验丰富专家及师傅，为您提供附近专业的检测和维修人员</h3>
				<div class="maintainBoxSome flex jc-between">
					<div class="maintainBoxSome1 ">

						<img src="../../../assets/osImg/os/maintain/repair_bg_diagnosis@2x.png" />

						<div class="boxTitle">
							设备检测
						</div>
						<div class="boxContent">
							检测专家，为你检测设备健康情况，定位设备问题根源，提供合理的保养维护建议
						</div>
					</div>
					<div class="maintainBoxSome2 ">

						<img src="../../../assets/osImg/os/maintain/repair_bg_repair@2x.png" />

						<div class="boxTitle">
							设备维修
						</div>
						<div class="boxContent">
							维修师傅，低成本快速为您完成设备的更换保养，对于中少企业无须储备维修师傅
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "maintain",
		data() {
			return {
				imgList: [{
						url: require("@/assets/osImg/os/maintain/repair_banner1.png")
					},
					{
						url: require("@/assets/osImg/os/maintain/repair_banner2.png")
					},
					{
						url: require("@/assets/osImg/os/maintain/repair_banner3.png")
					},
				],

			}
		}
	}
</script>

<style lang='scss'>
	.maintain {
		.maintainBox {
			min-height: 1100px;

			.maintainBoxSome {
				margin-bottom: 60px;
				width: 1148px;
				color: #fff;
				font-weight: 300;
				font-size: 24px;

				h3 {
					color: #fff;
					font-weight: 600;
					margin-top: 215px;
					font-size: 38px;
				}

				p {
					line-height: 40px;
					font-weight: 600;
					font-size: 24px;
				}

				.btn {
					width: 300px;
					height: 66px;
					background: #fff;
					text-align: center;
					line-height: 66px;
					color: #4A99F6;
					border-radius: 4px;
					cursor: pointer;
					margin-top: 214px;
					margin-left: 110px;
				}

				.maintainBoxSome1 {

					text-align: center;
					width: 562px;
					height: 788px;
					box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.4);

					img {
						width: 562px;
						height: 524px;
					}

					div {}
				}

				.maintainBoxSome2 {
					text-align: center;
					width: 562px;
					height: 788px;
					box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.4);

					img {
						width: 562px;
						height: 524px;
					}
				}

				.boxTitle {
					margin-top: 30px;
					color: #262626;
					height: 84px;
					font-size: 38px;
				}

				.boxContent {
					padding: 0 30px;
					height: 80px;
					font-size: 24px;
					color: #595959;
					line-height: 40px;
				}
			}

		}



		.maintainCase {
			min-height: 720px;
			background: #EFEFF4;

			.maintainCaseSon {
				width: 1148px;

				.title {
					font-size: 34px;
					color: #000;
				}

				.casezu {
					height: 525px;

					>div {
						width: 274px;
						height: 325px;
						background: #fff;

						flex: 1;

						.left {
							text-align: left;
						}

						p {
							font-size: 20px;
						}

						>img {
							width: 230px;
							height: 230px;
						}
					}
				}
			}
		}
	}
</style>
