<template>
<!-- 生态合作 -->
  <div class="cooperation">
       <!-- 轮播图 -->
    <div class="banner">
      <img src="../../../assets/osImg/os/cooperation/33.png" alt="" />
    </div>
    <!-- 文字 -->
      <div class="cooperationText pos-rel ">
         <div class="pos-xcenter cooperationSon">
              <h3 class="mb-30">让我们成为伙伴</h3>
          <p>构建开放协作、共享共赢的互联网能源新生态。在构建数字能源产业生态圈的进程中，面向能源用户市场，我们期待与你携手，一同提供卓越的服务和价值。</p>
         
         </div>
      </div>

      <!-- 描述 -->
        <div class="cootext flex jc-center ai-center" > 
                <div class="cootextSome pt-20 px-20 " style='backgroundColor:#e0b91a'>
                <h6 class="my-30">产品服务开放合作</h6>
                <p>共同为客户打造功能更强大的信息化平台，通过透明、分享、合作的态度，打造智能监测行业一站式服务。</p>
            </div>
        <div class="cootextSome pt-20 px-20 mx-20" style='backgroundColor:#4cd964'>
                <h6 class="my-30">设备制作商及维修专家</h6>
                <p>你的设备可通过我们的交易平台直接推荐厂家或销售，通过平台认证的设备维修专家，可获得各大厂家提供维修服务机会。</p>
            </div>
            <div class="cootextSome pt-20 px-20" style='backgroundColor:#5bc8fa'>
                <h6 class="my-30">产品服务开放合作</h6>
                <p>有客户关系及商务资源，愿意进一步拓展业务领域，共同推动商机落地，为客户提供持续运营服务的合作伙伴。</p>
            </div>
        </div>
  </div>
</template>

<script>
export default {
 name:'cooperation',

}
</script>

<style  lang="scss" scoped>
   .cootext{
       background-color: #fff;
        height: 430px;
        background-image: url("../../../assets/osImg/os/cooperation/features-bg.jpg");
        .cootextSome{
           height: 250px;
           width: 362px;
           color: #fff;
           
           h6{
               font-size: 18px;
           }
           p{
               font-size: 14px;
               text-align: left;
           }
        }
   }
</style>