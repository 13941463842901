<template>
	<!-- 设备租赁 -->
	<div class="lease text-center">

		<div class="banner">
			<el-carousel height="1000px">
				<el-carousel-item v-for="item in imgList" :key="item.url">
					<img :src="item.url" alt class="bannerImg " />
					  
				</el-carousel-item>
			</el-carousel>
		</div>

		<div class="leaseBox pos-rel">
			<div class=" pos-center ">
				<h3 class="my-60 ">避免设备备件积压资金，同时能保障生产正式</h3>
				<div class="leaseBoxSome flex jc-between">
					<div class="leaseBoxSome2 px-30">
						<div class="flex flex-col ai-center">
							<img class="mt-80 mb-15" src="../../../assets/osImg/os/lease/rent_ic_sell.png" alt="">
							<div class="fs-38 mb-60 ">我有设备</div>
						</div>
						<p>1、企业积压很多备件，放在哪里占地方也不产生价值 </p>
						<p class="mb-40">2、出租给别的企业，流程烦琐且容易发生争议不讨好</p>
						<span>通过设备租赁，为你提供收益和节省流程</span>
						<div class="btn">
							成为设备租赁提供商
						</div>
					</div>
					<div class="leaseBoxSome1 px-30">
						<div class="flex flex-col ai-center">
							<img class="mt-80 mb-15" src="../../../assets/osImg/os/lease/rent_ic_rent.png" alt="">
							<div class="fs-38 mb-60 ">我想租赁</div>
						</div>
						<p>1、为避免风险需要常备易损耗件，占地方且积压资金</p>
						<p class="mb-40">2、维修但没有备件或购买备件时间长，停产造成损失</p>
						<span>通过设备租赁，为你解决这些的问题</span>
						<div class="btn">
							进入设备租赁商城
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="leaseCase pos-rel">
			<div class="leaseCaseSon pos-xcenter">


				<h3 class="my-60">精选租赁产品</h3>


				<div class="casezu flex text-center">
					<div class="mr-20">
						<img src="../../../assets/osImg/os/lease/zhuixiang.png" alt="">
						<div class="title mt-20 mb-10">锥箱</div>
					</div>
					<div class="mr-20">
						<img src="../../../assets/osImg/os/lease/tusuji.png" alt="">
						<div class="title mt-20 mb-10">吐丝机</div>
					</div>
					<div class="mr-20">
						<img src="../../../assets/osImg/os/lease/zhaji.png" alt="">
						<div class="title mt-20 mb-10">双模块轧机</div>
					</div>
					<div>
						<img src="../../../assets/osImg/os/lease/kunxiang.png" alt="">
						<div class="title mt-20 mb-10">辊箱</div>
					</div>
				</div>


			</div>

		</div>



	</div>
</template>

<script>
	export default {
		name: "lease",
		data() {
			return {
				imgList: [{
						url: require("@/assets/osImg/os/lease/banner1.png")
					},
					{
						url: require("@/assets/osImg/os/lease/banner2.png")
					},
				],

			}
		}
	}
</script>

<style lang="scss">
	.lease {
		.leaseBox {
			min-height: 1000px;

			.leaseBoxSome {
				width: 1148px;
				color: #fff;
				font-weight: 300;
				font-size: 18px;

				h3 {
					color: #fff;
					font-size: 60px;
					line-height: 84px;
				}

				p {
					line-height: 40px;
					font-size: 18px;
				}

				.btn {
					width: 300px;
					height: 66px;
					background: #fff;
					text-align: center;
					line-height: 66px;
					color: #4A99F6;
					border-radius: 4px;
					cursor: pointer;
					margin-top: 100px;
					margin-left: 110px;
				}

				.leaseBoxSome1 {
					width: 562px;
					height: 700px;
					background-color: #f43f5e;
				}

				.leaseBoxSome2 {
					width: 562px;
					height: 700px;
					background-color: #3b82f6;
				}
			}

		}

		.leaseCase {
			min-height: 720px;
			background: #EFEFF4;

			.leaseCaseSon {
				width: 1148px;

				.title {
					font-size: 34px;
					color: #000;
				}

				.caseSome {
					height: 252px;

					.some {
						width: 700px;
						height: 252px;
						background: #fff;
						padding: 30px
					}

				}

				.casezu {
					height: 525px;

					>div {
						width: 274px;
						height: 325px;
						background: #fff;
						flex: 1;

						>img {
							width: 230px;
							height: 230px;
						}
					}

				}


			}


		}
	}
</style>
