<template>
	<!-- 核心技术 -->
	<div class="technology">
		<!-- 轮播图 -->
		<div class="banner">
			<img src="../../../assets/osImg/os/technology/2222.png" alt="" />
		</div>
		<!-- 文字 -->
		<div class="technologyText pos-rel ">
			<div class="pos-xcenter technologySon">
				<h3 class="mb-30">核心技术</h3>
				<p>数字孪生：充分利用物理模型、传感器、历史等数据，利用三维预案可视化和图形渲染引擎组成，让用户通过3D模拟场景，直观了解设备的整体运行情况，快速定位问题部件。</p>
				<p class="mt-30">智能算法：基于大数据及人工智能技术，通过业务与平台实时对接、业务模型实时回溯调整，形成了主动预测、结果研判，最终实现智能算法设备报错，设备残余寿命计算等。</p>
			</div>
		</div>
		<!-- 图片 -->
		<div class="technologyPho pos-rel ">
			<div class="pos-xcenter">
				<img src="../../../assets/osImg/os/technology/c1.jpg" alt="">
				<img src="../../../assets/osImg/os/technology/c2.jpg" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "technology",
	}
</script>

<style>

</style>
