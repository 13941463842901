<template>
	<!-- 关于我们 -->
	<div class="aboutUs text-center">
		<!-- 轮播图 -->
		<div class="banner" style="background-color: #f0f2f5;">
			<img src="../../../assets/osImg/os/aboutUs.png" style="width: 100%;" alt="" />
		</div>
		<!-- 文字 -->
		<div class="aboutUsText pos-rel ">
			<div class="pos-xcenter aboutUsSon">
				<h3 class="mb-30">圣名科技</h3>
				<p>圣名科技是IOT业内的硬件以及软件方案提供商，提供工业级别产线的全周期运维服务。服务包括工业设备硬件数据采集，智能算法报错与诊断，产线设备残余寿命监控，设备租赁，售卖和维修，陆地机器人，无人机监控运维以及全球分布式大数据AI运维平台中心。
				</p>
				<p class="mt-30">
					在“全面数字化”和“全面智能化”的战略下，智能生产IoT围绕“人和工业生产”进行物联网全生态价值链建设，探索以用户为中心的商业模式，以“软件、硬件、数据、服务”为基础，从智能工业生产的隐私安全保护、智能场景内容精细化运营、智能连接技术开发、智能产线的品牌建设、云平台建设、AI算法，VR，AR，MR和大数据云管家等的全方位的技术研发与实施。
				</p>
			</div>
		</div>
		<!-- 图片 -->

		<div class="aboutUsPho pos-rel">
			<div class="aboutUsPhoSon pos-xcenter flex jc-center ai-center">
				<div class="aboutUsPhoSome mx-15" v-for="item in toolList" :key="item.url">
					<img :src="item.url" alt="" />
					<div class="mx-20 mt-15 fs-20 text-bold">
						{{ item.title }}
					</div>
				</div>
			</div>
		</div>


	</div>



</template>

<script>
	export default {
		name: 'aboutUs',
		data() {
			return {
				toolList: [{
						title: "合作企业",
						url: require("@/assets/osImg/about1.jpg"),

					},
					{
						title: "知识产权",
						url: require("@/assets/osImg/about2.jpg"),

					},
					{
						title: "体系认证",
						url: require("@/assets/osImg/about3.jpg"),

					},
				],
			}
		}
	}
</script>

<style>

</style>
