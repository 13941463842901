<template>
	<!-- 官网首页 -->
	<div class="osIndex">
		<!-- 轮播图 -->
		<div class="banner pos-rel">
			<el-carousel height="1000px">
				<el-carousel-item v-for="item in imgList" :key="item.url">
					<img :src="item.url" alt class="bannerImg" />
					<!-- <div class="banText">
         {{item.text}}
      </div> -->
					  
				</el-carousel-item>
			</el-carousel>


		</div>
		<!-- 服务详情 -->
		<div class="details flex flex-wrap jc-around ai-center py-50">
			<div class="deltailsSome" v-for="item in detailsList" :key="item.url">
				<div class="flex pb-15">
					<img :src="item.url" alt="" />
					<h5>{{ item.title }}</h5>
				</div>
				<div>{{ item.text }}</div>
			</div>
		</div>

		<!-- 工具 -->
		<div class="tool flex jc-center ai-center">
			<div class="toolSome mx-15" v-for="item in toolList" :key="item.url">
				<img :src="item.url" alt=""style="width: 362px;height: 336px;" />
				<div class="mx-20 mt-15">
					<span>{{ item.title }}</span>
					<p class="mt-15">{{ item.text }}</p>
				</div>
			</div>
		</div>

		<!-- 文字 -->
		<div class="teamTitle">
			<div class="teamTitleSome my-60">
				<h3>我们是一个有丰富业务知识支撑的技术型团队</h3>
				<p>
					智能化服务过程中，对包括智慧人机管理、智慧检测执行、智慧决策支持、智慧业务协同、智慧设备管控五个层面为着力点，加快工业智能设备互联建设步伐，进而达到推动企业整体管理水平和技术水平提高的最终目的。
				</p>
			</div>
		</div>

		<!-- 需求 -->
		<div class="need flex">
			<div class="flex-1">
				<img src="../../../assets/osImg/os/index/testimonial-bg.jpg" alt="" />
			</div>
			<div class="flex-1 flex ai-center jc-center">
				<div class="carouselBox">
					<el-carousel height="460px" width="320px" arrow="never">
						<el-carousel-item v-for="item in textBan" :key="item.title">
							<div>
								<div class="title">{{ item.title }}</div>
								<div class="text" v-for="(i, index) in item.textChild" :key="index">
									{{ i.title }}
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "osIndex",
		data() {
			return {
				imgList: [{
						url: require("@/assets/osImg/os/index/home1.png"),
						text: '打造全透明数字化工厂增加竞争力'
					},
					{
						url: require("@/assets/osImg/os/index/home2.png"),
						text: ' 实现从车间到监测层的纵向互联'
					},
				],
				textBan: [{
						title: "需求痛点",
						textChild: [{
								title: "突发故障抢修：人员安全威胁！生产中断！生产和维修成本大幅提升",
							},
							{
								title: "设备管理难题：设备运行状态数据不及时、不集中、不精细"
							},
							{
								title: "员工方面挑战：企业难以留住人才，年轻人越来越少的从事一线工作",
							},
						],
					},
					{
						title: "预测价值",
						textChild: [{
								title: "提前预警判断：对设备运行状态进行在线监测，判断设备异常和故障趋势",
							},
							{
								title: "避免风险支出：降低设备突发故障的停产损失，一次意外停机可损失几十万以上",
							},
							{
								title: "维修效率提升：根据监测结果智能定位故障，提前准备备品备件，或求助外部专家",
							},
						],
					},
				],

				detailsList: [{
						title: "设备监控",
						url: require("@/assets/osImg/icons/cogwheel.png"),
						text: "专注于工业设备预测维护领域，通过感知产品，实现设备在线监测，为用户提供智能预警、智能诊断服务，减少和防范风险的发生。",
					},
					{
						title: "软件平台",
						url: require("@/assets/osImg/icons/helmet.png"),
						text: "采用集群化SaaS部署方式，可根据业务的需求范围进行动态调整，基于数据的私密的需求，同时支持私有化部署，支持移动和PC端。",
					},
					{
						title: "数据分析",
						url: require("@/assets/osImg/icons/wind-engine.png"),
						text: "通过跨端跨触点，聚合多种数据源，以报表、大屏等形式，根据不同的指标体系，对关键节点的数据进行深度下钻分析，衡量和洞察设备问题。",
					},
					{
						title: "算法模型",
						url: require("@/assets/osImg/icons/pollution.png"),
						text: "基于大数据及人工智能技术，通过业务与平台实时对接、形成了主动预测、结果研判，最终实现智能算法设备报错，设备残余寿命计算等。",
					},
					{
						title: "交易服务",
						url: require("@/assets/osImg/icons/pumpjack.png"),
						text: "实现“设备→设备使用企业→设备服务商→设备制造商→平台运营商”的全数字化打通，为供求双方提供在线产品、信息、及服务交易。",
					},
					{
						title: "维修服务",
						url: require("@/assets/osImg/icons/light-bulb.png"),
						text: "整合行业的生产运营专家、工程专家、高级技师行设备线进咨询及线下维修服务，助力设备管理维护模式转型，进而提高生产力,降低运维成本。",
					},
				],

				toolList: [{
						title: "硬件工具",
						url: require("@/assets/osImg/os/index/d1.png"),
						text: "提供采集硬件的整体解方案，包括无线有线两种技术，数据可根据私密性要求，采用指定储存方式。",
					},
					{
						title: "诊断工具",
						url: require("@/assets/osImg/os/index/d2.png"),
						text: "提供多诊断图谱，包括多图联动、游标工具、轴承频率、对比分析，结合数字孪生技术向您展示。",
					},
					{
						title: "业务工具",
						url: require("@/assets/osImg/os/index/d3.png"),
						text: "为您的设备保驾护航，通过交易平台快速替换问题设备，通过专家平台为您诊断和维修服务。",
					},
				],
			};
		},
		mounted() {},
		methods: {},
	};
</script>

<style lang="scss" scoped>
	.tool {
		margin-top: 406px;
		height: 806px;
		background-image: url("../../../assets/osImg/features-bg.jpg");
	}

	.flex-1 {
		background: #17172d;

		img {
			display: block;
		}
	}

	.aa {
		height: 1000px;
		color: aqua;
	}
</style>
