<template>
	<!-- 电商 -->
	<div class="purchase text-center">
		<div class="banner">
			<el-carousel height="1000px">
				<el-carousel-item v-for="item in imgList" :key="item.url">
					<img :src="item.url" alt class="bannerImg " />
					  
				</el-carousel-item>
			</el-carousel>
		</div>

		<div class="purchaseBox pos-rel">
			<div class=" pos-center ">
				<h3 class="my-60">避免设备备件积压资金，同时能保障生产正式运行</h3>
				<div class="purchaseBoxSome flex jc-between">
					<div class="purchaseBoxSome1 px-30">

						<h3 class=" mb-60 ">交易保障</h3>

						<p class=" text-center">为供求双方提供一站式采购交易服务，平台保障交易的真实及安全性</p>

						<div class="btn">
							我想采购设备
						</div>
					</div>
					<div class="purchaseBoxSome2 px-30">

						<h3 class=" mb-60 ">专业专注</h3>
						<p class="text-center">让行业采购更透明，解决信息不对称，让你用更低的成本采购质量更佳的设备 </p>
						<div class="btn">
							我想成为供应商
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="purchaseCase pos-rel">
			<div class="purchaseCaseSon pos-xcenter">

				<h3 class="my-60">精选设备品牌</h3>


				<div class="casezu flex text-center">
					<div class="mr-20">
						<img src="../../../assets/osImg/os/purchase/zhoucheng.png" alt="">
						<div class="title mt-20 mb-10">油膜轴承</div>

					</div>
					<div class="mr-20">
						<img src="../../../assets/osImg/os/purchase/yuanzhoucheng.png" alt="">
						<div class="title mt-20 mb-10">全圆式油膜轴承</div>
					</div>
					<div class="mr-20">
						<img src="../../../assets/osImg/os/purchase/kunzhou.png" alt="">
						<div class="title mt-20 mb-10">轧机辊轴</div>
					</div>
					<div>
						<img src="../../../assets/osImg/os/purchase/zhuitao.png" alt="">
						<div class="title mt-20 mb-10">不锈钢锥套</div>
					</div>
				</div>


			</div>

		</div>


	</div>
</template>

<script>
	export default {
		name: "purchase",
		data() {
			return {
				imgList: [{
						url: require("@/assets/osImg/os/purchase/shoping_banner1.png")
					},
					{
						url: require("@/assets/osImg/os/purchase/shoping_banner2.png")
					},
				],

			}
		}
	}
</script>

<style lang='scss'>
	.purchase {
		.purchaseBox {
			min-height: 1000px;

			.purchaseBoxSome {
				width: 1148px;
				color: #fff;
				font-weight: 300;
				font-size: 24px;

				h3 {
					color: #fff;
					font-weight: 600;
					margin-top: 215px;
					font-size: 38px;
				}

				p {
					line-height: 40px;
					font-weight: 600;
					font-size: 24px;
				}

				.btn {
					width: 300px;
					height: 66px;
					background: #fff;
					text-align: center;
					line-height: 66px;
					color: #4A99F6;
					border-radius: 4px;
					cursor: pointer;
					margin-top: 214px;
					margin-left: 110px;
				}

				.purchaseBoxSome1 {
					width: 562px;
					height: 788px;
					background-color: #f59e0b;
				}

				.purchaseBoxSome2 {
					width: 562px;
					height: 788px;
					background-color: #818cf8;
				}
			}

		}



		.purchaseCase {
			min-height: 720px;
			background: #EFEFF4;

			.purchaseCaseSon {
				width: 1148px;

				.title {
					font-size: 34px;
					color: #000;
				}

				.casezu {
					height: 525px;

					>div {
						width: 274px;
						height: 325px;
						background: #fff;

						flex: 1;

						.left {
							text-align: left;
						}

						p {
							font-size: 20px;
						}

						>img {
							width: 230px;
							height: 230px;
						}
					}
				}
			}
		}
	}
</style>
