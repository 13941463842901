<template>
	<div class="os">
		<!-- 头部 -->
		<header>
			<div class="os-header flex jc-between fs-12 py-15" style="height: 40px;font-size: 12px;line-height: 10px;">
				<div class="ml-40">
					<span>致力成为国内领先的智能在线监测服务提供商</span>
				</div>
				<div class="mr-40">
					<span>算法引擎、数字孪生、与AI技术为您转型数字化未来</span>
				</div>
			</div>
		</header>

		<!-- 导航 -->
		<nav>
			<div class="os-nav ai-center flex px-50 py-20">
				<div class="logo flex-2">
					<img src="../../assets/osImg/osLogo.png" alt="" style="width:264px;height: 48px;" />
				</div>
				<div class="tabs flex-6">
					<el-tabs v-model="activeName">
						<el-tab-pane label="首页" name="index"></el-tab-pane>
						<el-tab-pane label="产品中心" name="product"></el-tab-pane>
						<el-tab-pane label="设备租赁" name="lease"></el-tab-pane>
						<el-tab-pane label="电商采购" name="purchase"></el-tab-pane>
						<el-tab-pane label="圣名维修" name="maintain"></el-tab-pane>
						<el-tab-pane label="核心技术" name="technology"></el-tab-pane>
						<el-tab-pane label="生态合作" name="cooperation"></el-tab-pane>
						<el-tab-pane label="关于我们" name="about"></el-tab-pane>
						<!-- <el-tab-pane label="App下载" name="App"></el-tab-pane> -->
					</el-tabs>
				</div>
				<div class="actionBut flex-2">
					<el-button @click="toMall"
						style="color: rgba(74, 153, 246, 1);border-color: rgba(74, 153, 246, 1);">交易服务平台</el-button>
					<el-button type="primary" @click="toHome">智能监测平台</el-button>
				</div>
			</div>
		</nav>

		<!-- 组件详情 -->
		<section>
			<osIndex v-if="activeName == 'index'"></osIndex>
			<product v-if="activeName == 'product'"></product>
			<technology v-if="activeName == 'technology'"></technology>
			<cooperation v-if="activeName == 'cooperation'"></cooperation>
			<aboutUs v-if="activeName == 'about'"></aboutUs>
			<lease v-if="activeName == 'lease'"></lease>
			<maintain v-if="activeName == 'maintain'"></maintain>
			<purchase v-if="activeName == 'purchase'"></purchase>
		</section>

		<!-- 联系方式 -->
		<div class="os-relation py-50 ">
			<div style="width:100%" class="relationSon flex jc-between">

				<div style="flex:1">
					<div class="relationSonTitle">客户服务</div>
					<div class="flex jc-center">
						<div class="zaixiankefu">
							<img src="../../assets/osImg/os/zxkf.png" alt="" />
							<div>在线客服</div>
						</div>
						<div class="wentifankui">
							<img src="../../assets/osImg/os/wtfk.png" alt="" />
							<div>问题反馈</div>
						</div>
					</div>

				</div>
				<div style="flex:1">
					<div class="relationSonTitle">扫描下载APP</div>
					<img src="../../assets/osImg/os/appCode.png" style="width: 157px;height: 157px;" />
				</div>
				<div style="flex:1">
					<div class="relationSonTitle">服务热线</div>
					<div style="font-size: 28px;font-weight: 400;color: #333333;margin-top: 50px;">020-31802914</div>
					<div style="font-size: 24px;font-weight: 400;color: #999999;margin-top: 8px;">周一至周日 9:00-18:00</div>
					<!-- <div style="font-size: 24px;font-weight: 400;color: #999999;margin-top: 20px;">（仅收市话费）</div> -->
				</div>
				<div style="flex:1">
					<div class="relationSonTitle">业务咨询</div>
					<div style="font-size: 28px;font-weight: 400;color: #333333;margin-top: 50px;">17727660851</div>
					<div style="font-size: 24px;font-weight: 400;color: #999999;margin-top: 8px;">周一至周日 9:00-18:00</div>
					<!-- <div style="font-size: 24px;font-weight: 400;color: #999999;margin-top: 20px;">（仅收市话费）</div> -->
				</div>
			</div>
		</div>

		<!-- 底部 -->
		<footer>
			<div class="os-footer flex jc-center">
				<div v-for="item in list" :key="item.url">
					<div class="mx-15">{{item.title}}</div>
				</div>
			</div>
		</footer>
		<div style="height: 40px;text-align: center;line-height: 40px;">
			<sm-footer></sm-footer>
		</div>

	</div>
</template>

<script>
	import smFooter from "@c/smFooter.vue"
	import caches from "@cache/cache.js"
	import osIndex from './component/osIndex'
	import product from './component/product'
	import technology from './component/technology'
	import cooperation from './component/cooperation'
	import aboutUs from './component/aboutUs'
	import lease from './component/lease'
	import maintain from './component/maintain'
	import purchase from './component/purchase'
	export default {
		components: {
			smFooter,
			osIndex,
			product,
			technology,
			cooperation,
			aboutUs,
			lease,
			maintain,
			purchase
		},
		data() {
			return {
				activeName: "index",
				list: [{
						title: '首页'
					},
					{
						title: '产品中心'
					},
					{
						title: '设备租赁'
					},
					{
						title: '电商采购'
					},
					{
						title: '圣名维修'
					},
					{
						title: '核心技术'
					},
					{
						title: '生态合作'
					},
					{
						title: '关于我们'
					}
				]
			};

		},
		methods: {
			toHome() {
				caches.loginLocation.set('home');
				const {
					href
				} = this.$router.resolve({
					path: "/home",
				})
				window.open(href, '_blank');
			},
			toMall() {
				caches.loginLocation.set('diagnosis');
				const {
					href
				} = this.$router.resolve({
					path: "/Mall",
				})
				window.open(href, '_blank');
			}
		}
	};
</script>

<style lang='scss'>
	.os-relation {
		background: #FFFFFF;
		min-height: 400px;

		.relationSon {
			width: 1148px;

			>div {
				width: 300px;
				text-align: center;

				.relationSonTitle {
					font-weight: 600;
					color: #333333;
					line-height: 45px;
					font-size: 32px;
					margin-bottom: 31px;
				}

				.zaixiankefu {
					width: 110px;
					height: 110px;
					border-radius: 8px;
					border: 1px solid #A4A9B0;
					margin:20px;

					img {
						width: 44px;
						height: 44px;
						margin-top: 10px;
					}

					>div {
						margin-top: 10px;
						font-size: 16px;
						font-weight: 400;
						color: #333333;
					}
				}

				.wentifankui {
					margin-top: 20px;
					width: 110px;
					height: 110px;
					border-radius: 8px;
					border: 1px solid #A4A9B0;

					>img {
						width: 44px;
						height: 44px;
						margin-top: 10px;
					}

					>div {
						margin-top: 10px;
						font-size: 16px;
						font-weight: 400;
						color: #333333;
					}
				}
			}
		}
	}
</style>
