<template>
  <!-- 产品中心 -->
  <div class="product text-center">
    <!-- 轮播图 -->
    <div class="banner">
      <img src="../../../assets/osImg/os/product/banner5555.png" alt="" />
    </div>
    <!-- 产品 -->
    <div class="productSon mt-80">
      <h3>无人工厂 MAAS</h3>
      <p class="mt-30">
        公共服务机器人·安防巡检机器人
      </p>
      <div class="tool flex jc-center ai-center mt-50">
        <div class="mx-10" v-for="item in robot1" :key="item.url">
          <img :src="item.url" alt="" style="width: 563px;" />
        </div>
      </div>
    </div>
    <div class="productSon ">
      <div class="tool flex jc-center ai-center mt-20">
        <div class="mx-10" v-for="item in robot2" :key="item.url">
          <img :src="item.url" alt="" style="width: 563px;" />
        </div>
      </div>
    </div>
    <div class="productSon mt-80">
      <h3>IOT硬件产品</h3>
      <p class="mt-30">
        提供监测、采集等硬件，实时传输、边缘计算等现场整体解决方案
      </p>
      <div class="tool flex jc-center ai-center mt-50">
        <div class="mx-15" v-for="item in List" :key="item.url">
          <img :src="item.url" alt="" style="width: 362px;height: 217px;" />
        </div>
      </div>
    </div>

    <div class="productSon mt-80">
      <h3>SAAS全生态周期运维平台WEB + APP</h3>
      <p class="mt-30">
        提供集AI智能监控、图谱分析、产品采购、专家诊断与维修为一体的全周期SAAS运维平台
      </p>
      <div class="tool flex jc-center ai-center mt-50">
        <div class="mx-15" v-for="item in Arr" :key="item.url">
          <img :src="item.url" alt="" style="width: 362px;height: 217px;" />
        </div>
      </div>
    </div>

   <!-- 图片 -->
      <div class="productImg mt-20" v-for="item in imgList" :key="item.url">
          <img :src="item.url" alt="">
      </div>
      
  </div>
</template>

<script>
export default {
  name: "product",
  data() {
    return {
		robot1:[
        {
          url: require("@/assets/osImg/os/product/robot2.png"),
        },
        {
          url: require("@/assets/osImg/os/product/robot4.png"),
        },],
		robot2:[
        {
          url: require("@/assets/osImg/os/product/robot1.png"),
        },
        {
          url: require("@/assets/osImg/os/product/robot3.png"),
        },],
      List: [
        {
          url: require("@/assets/osImg/os/product/z1.png"),
        },
        {
          url: require("@/assets/osImg/os/product/z2.png"),
        },
        {
          url: require("@/assets/osImg/os/product/z3.png"),
        },
      ],
      Arr: [
        {
          url: require("@/assets/osImg/os/product/z4.png"),
        },
        {
          url: require("@/assets/osImg/os/product/z5.png"),
        },
        {
          url: require("@/assets/osImg/os/product/z6.png"),
        },
      ],
      imgList:[{
         url: require("@/assets/osImg/os/product/banner4.png"),
      },
      {
         url: require("@/assets/osImg/os/product/banner2.png"),
      },
      {
         url: require("@/assets/osImg/os/product/banner3.png"),
      },
      
      ]
    };
  },
};
</script>

<style lang='scss'>
  
</style>